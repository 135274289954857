import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const thumbnailImage = video => {
  return (
    <Img
      fluid={video.thumbnail.childImageSharp.fluid}
      alt={video.sessionTitle}
    />
  );
};

const thumbnailImageFallback = video => {
  return (
    <picture className="u-widescreen-media__inner">
      <img
        src={`https://play.vidyard.com/${video.vidyardId}.jpg`}
        alt={video.sessionTitle}
        className="u-widescreen-media__image"
      />
    </picture>
  );
};

const VideoThumbnail = ({ video }) => {
  return video.thumbnail
    ? thumbnailImage(video)
    : thumbnailImageFallback(video);
};

VideoThumbnail.propTypes = {
  video: PropTypes.shape().isRequired
};

export default VideoThumbnail;
