import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../contexts/AuthContext';
// console.log(
//   `Is Trailhead User Authenticated?: ${isTrailheadUserAuthenticated()}`
// );

const IndexTemplate = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  // GTM dataLayer
  // Delaying execution because this data comes from the Trailhead Header
  useEffect(() => {
    // console.log('useEffect', isTrailheadUserAuthenticated())
    const timer = setTimeout(() => {
      // console.log('timeOut', isTrailheadUserAuthenticated());
      window.dataLayer = window.dataLayer || [];
      // prettier-ignore
      // Use quotes for variable names https://developers.google.com/tag-manager/devguide#variable-names-should-be-enclosed-in-quotes
      window.dataLayer.push({
        'event': 'trailheadUser',
        'trailhead-user': isLoggedIn
      });
    }, 5000);
    // console.log('clearTimeOut(timer)', isTrailheadUserAuthenticated());
    return () => clearTimeout(timer);
  });
  // console.log('outside', isTrailheadUserAuthenticated());

  return <>{children}</>;
};

IndexTemplate.propTypes = {
  children: PropTypes.node.isRequired
};

export default IndexTemplate;
