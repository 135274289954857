import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../CustomLink';
import DatetimeUtils from '../../utils/DatetimeUtils';
import SessionUtils from '../../utils/SessionUtils';
import Speaker from '../Speaker';
import VideoThumbnail from '../VideoThumbnail';

const OnDemandCard = ({ item }) => {
  const { datetime, sessionTitle, lengthOfSession, speakers, slug } = item;

  const localizedDate = timestamp => {
    return `${DatetimeUtils.getLocalizedDate(timestamp)}, ${new Date(
      timestamp
    ).getFullYear()}`;
  };
  const { speakerName, imageUrl, speakerTBIDProfile } = speakers[0];
  const path = `/videos/${slug}`;
  // play.vidyard.com/${vidyardId}.html?v=3.1.1&autoplay=0
  return (
    <article className="tlv-card-on-demand slds-card tds-card">
      <div
        className="tlv-card-on-demand__thumbnail"
        data-testid="on-demand-thumbnail"
      >
        <Link to={path} className="u-display-block u-widescreen-media">
          <VideoThumbnail video={item} />
        </Link>
      </div>
      <div className="tlv-card-on-demand__details">
        <h3 className="tlv-card-on-demand__title" data-testid="on-demand-title">
          <Link to={path}>{sessionTitle}</Link>
        </h3>
        <div
          className="tlv-card-on-demand__speaker"
          data-testid="on-demand-speaker"
        >
          <Speaker
            imageUrl={imageUrl}
            speakerName={speakerName}
            speakerSubtext={localizedDate(datetime)}
            speakerTBIDProfile={speakerTBIDProfile}
          />
          <div
            className="tlv-card-on-demand__duration"
            data-testid="on-demand-duration"
          >
            {SessionUtils.getLengthOfSession(lengthOfSession)}
          </div>
        </div>
      </div>
    </article>
  );
};

OnDemandCard.propTypes = {
  item: PropTypes.shape().isRequired
};

export default OnDemandCard;
