import { addDays, isSameDay } from 'date-fns';

const filterLiveScheduleGroups = ({
  upcomingVideos: videos,
  // default values to enable testing
  time = Date.now()
}) => {
  const buckets = {
    TODAY: [],
    NEXT_7_DAYS: [],
    NEXT_14_DAYS: [],
    NEXT_30_DAYS: [],
    ALL: []
  };
  const today = new Date(time);
  const datesFromNow = {
    sevenDays: addDays(today, 7).getTime(),
    fourteenDays: addDays(today, 14).getTime(),
    thirtyDays: addDays(today, 30).getTime()
  };
  videos.forEach(video => {
    const videoDate = new Date(video.datetime);
    const videoTime = videoDate.getTime();

    if (isSameDay(videoDate, today)) {
      buckets.TODAY.push(video);
    }
    if (videoTime <= datesFromNow.sevenDays) {
      buckets.NEXT_7_DAYS.push(video);
    }
    if (videoTime <= datesFromNow.fourteenDays) {
      buckets.NEXT_14_DAYS.push(video);
    }
    if (videoTime <= datesFromNow.thirtyDays) {
      buckets.NEXT_30_DAYS.push(video);
    }
    buckets.ALL.push(video);
  });

  return ({ filterBy }) => {
    const message = `Invalid filter type ${filterBy}. Provide one of the following to the 'filterBy' property:
    'TODAY',
    'NEXT_7_DAYS',
    'NEXT_14_DAYS',
    'NEXT_30_DAYS',
    'ALL' `;

    if (!buckets[filterBy]) {
      // eslint-disable-next-line no-console
      console.error(message);
      return [];
    }
    return buckets[filterBy];
  };
};

export default filterLiveScheduleGroups;
